/* 
  We've already included normalize.css. 

  But we'd like a modern looking boilerplate. 
  Clean type, sans-serif, and a nice color palette. 
  
*/

body {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  font-weight: 600;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

#app {
  padding: 1rem;
}

/* Container Styles */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f8ff; /* Голубой фон */
  border: 1px solid #ccc; /* Серая граница */
  border-radius: 5px; /* Скругленные углы */
}

/* Toolbar Styles */
.toolbar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.btn {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px; /* Скругленные углы кнопок */
}

.btn:hover {
  background-color: #555;
}

/* Setting Bar Styles */
.setting-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.setting-bar label {
  margin-right: 10px;
}

.setting-bar input[type="number"] {
  width: 60px;
  padding: 5px;
  text-align: center;
  border: 1px solid #ccc; /* Серая граница для числового поля */
  border-radius: 3px; /* Скругленные углы числового поля */
}

.setting-bar input[type="color"] {
  width: 100px;
  height: 30px;
  border: none;
  padding: 0;
  border-radius: 3px; /* Скругленные углы поля выбора цвета */
}

/* Canvas Styles */
.canvas {
  display: flex;
  justify-content: center;
}

canvas {
  border: 1px solid #ccc; /* Серая граница для холста */
  border-radius: 5px; /* Скругленные углы холста */
}
